<template>
  <div class="el-upload-model">
    <el-upload
      class="el-avatar-uploader"
      action="#"
      ref="fileUpload"
      :show-file-list="showFileList"
      :http-request="handleUploadImg"
      :before-upload="beforeUpload"
      :on-preview="handleContractUploadPreview"
      :on-remove="handleContractUploadRemove"
      :disabled="disabled"
      :multiple="multiple"
      :limit="limit"
      :accept="accept"
      :file-list="uploadFileList"
    >
      <el-button
        :disabled="disabled"
        size="small"
        type="primary"
        ref="uploadContractBtn"
        v-show="isShowBtn"
        >{{ buttonText }}</el-button
      >
      <i
        class="el-icon-upload"
        style="font-size:32px;"
        v-show="isShowIcon && uploadFileList.length < limit"
      ></i>
      <slot name="preview"></slot>
      <div slot="tip" class="el-upload__tip" v-if="uploadTip">
        {{ uploadTip }}
      </div>
    </el-upload>
  </div>
</template>

<script>
import { uploadOss, getObsPostPolicy, downFile, saveFile } from "@/api";
import { exportExcelByUrl } from "@/utils/download";
import { typeOf, printError } from "@/utils/util";
import { Loading } from "element-ui";
import setting from "@/setting";

import { uuid } from "vue-uuid";

export default {
  name: "uploadOBS",
  props: {
    //是否一直显示上传按钮,0不显示上传按钮,1一直显示上传按钮，2上传数大于最大上传数，隐藏按钮
    showBtnType: {
      type: [String, Number],
      default: 2
    },
    isShowIcon: {
      type: Boolean,
      default: false
    },
    //是否需要加载效果
    needfullscreenLoading: {
      type: Boolean,
      default: true
    },
    //上传的文件列表
    uploadFileList: {
      type: Array,
      default: () => []
    },
    //上传的文件类型
    fileType: {
      type: [Number, String],
      default: 1
    },
    //组件绑定的value
    value: {
      type: [Number, String, Array]
    },
    //是否支持多选
    multiple: {
      type: Boolean,
      default: false
    },
    //上传最大数量
    limit: {
      type: Number,
      default: 100
    },
    //文件最大size,单位MB
    limitSize: {
      type: [Number, String],
      default: 100
    },
    //上传前需校验文件类型配置,例如:["jpg","png","jpeg"]
    limitConfig: {
      type: Array,
      default: () => []
    },
    //上传文本提示
    uploadTip: {
      type: String,
      default: ""
    },
    showFileList: {
      type: Boolean,
      default: true
    },
    //element属性，接受的文件类型
    accept: {
      type: String,
      default: ""
    },
    //是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    //按钮文本
    buttonText: {
      type: String,
      default: "上传文件"
    },
    //是否需要remove操作,防止上传失败时，覆盖了之前得值
    canRemove: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      imageUrl: "",
      initiateData: {
        option: {},
        uploadId: ""
      },
      fileUrl: "",
      configData: {
        endPoint: "",
        bucketName: "",
        signature: "",
        "x-obs-acl": "public-read",
        "content-type": "text/plain",
        key: "",
        url: "",
        policy: "",
        accesskeyid: ""
      }
    };
  },
  computed: {
    isShowBtn() {
      let value = true;
      if (this.showBtnType == 0) {
        //一直不显示按钮
        value = false;
      } else if (this.showBtnType == 1) {
        //一直显示上传按钮
        value = true;
      } else {
        value =
          !this.uploadFileList.length || this.uploadFileList.length < this.limit
            ? true
            : false;
      }
      return value;
    }
  },
  methods: {
    //上传前对文件做一些限制处理
    beforeUpload(file) {
      if (file && file.name && typeof file.name === "string") {
        let index = file.name.lastIndexOf(".");
        let suffix = file.name.substring(index + 1);
        let limitConfig = this.limitConfig;
        if (limitConfig.length) {
          if (limitConfig.lastIndexOf(suffix) == -1) {
            //不是所限制的类型
            let limitConfigStr = limitConfig.join("/");
            this.$message.warning(`只能上传${limitConfigStr}类型的文件`);
            return false;
          }
        }
      }
      if (this.limitSize > 0 && file.size / 1024 / 1024 > this.limitSize) {
        this.$message.info(`上传文件大小不能超过 ${this.limitSize}MB!`);
        return false;
      }
      if (this.needfullscreenLoading) {
        const { loadingBackground } = setting;
        this.loadingService = Loading.service({
          fullscreen: true,
          background: loadingBackground,
          text: "文件上传中。。。"
        });
      }
      return true;
    },
    //上传文件
    handleUploadImg(file) {
      this.getObsSignReq(file);
    },
    //获取签名请求
    async getObsSignReq(file) {
      try {
        let fileName = uuid.v1() + "." + file.file.name;
        let res = await getObsPostPolicy({
          fileType: this.fileType,
          fileName
        });
        if (res.code === "OK") {
          this.configData = res.data;
          this.progressFlag = true;
          this.uploadOssReq(file, fileName);
        }
      } catch (error) {
        console.log(error, "签名获取失败");
      }
    },
    //上传到华为云请求
    async uploadOssReq(file, fileName) {
      try {
        let configData = this.configData;
        let formData = new FormData();
        let key = configData.key + "/" + fileName;
        formData.append("key", key);
        formData.append("x-obs-acl", "public-read");
        formData.append("content-type", "text/plain");
        formData.append("policy", configData.policy);
        formData.append("AccessKeyId", configData.accesskeyid);
        formData.append("signature", configData.signature);
        formData.append("file", file.file);
        await uploadOss(
          {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*"
          },
          configData.url,
          formData
        );
        let allFileUrl =
          "https://e3-oss.obs.cn-east-3.myhuaweicloud.com/" + key;
        let fileObj = {
          allFileUrl,
          dir: key,
          fileType: this.fileType,
          fileName
        };
        this.saveFileReq(fileObj);
      } catch (error) {
        console.log(error, "==");
      }
    },
    //获取文件id
    async saveFileReq(fileObj) {
      try {
        let res = await saveFile([fileObj]);
        let resData = res.data[0];
        if (typeOf(this.value) == "String" || typeOf(this.value) == "Number") {
          //只传一个
          this.$emit("input", resData.fileId);
        } else if (typeOf(this.value) == "Array") {
          let fileArr = [...this.value];
          fileArr.push(resData.fileId);
          this.$emit("input", fileArr);
        }
        let uploadFileList = this.uploadFileList;

        let obj = {
          fileId: resData.fileId,
          name: resData.fileName,
          fileUrl: resData.allFileUrl
        };
        uploadFileList.push(obj);
        this.$emit("update:uploadFileList", uploadFileList);
        this.$emit("success", res);
        this.$refs.fileUpload.clearFiles(); //上传成功之后清除历史记录
      } catch (error) {
        console.log(error, "签名获取失败");
      } finally {
        if (this.loadingService) this.loadingService.close();
      }
    },
    // 点击文件列表中已上传的文件时的钩子
    handleContractUploadPreview(file) {
      if (file.fileId) {
        if (file.fileUrl) {
          exportExcelByUrl(file.fileUrl);
        } else {
          this.downFile(file.fileId);
        }
      }
    },
    // 获取文件地址
    async downFile(id) {
      try {
        let res = await downFile({ id });
        exportExcelByUrl(res.data.fileUrl);
      } catch (error) {
        printError(error);
      }
    },
    // 删除时的处理方法
    handleContractUploadRemove(file, fileList) {
      // this.uploadFileList = fileList;
      //上传失败得时候会调用一次remove方法
      if (!this.canRemove) return;
      if (typeOf(this.value) == "String" || typeOf(this.value) == "Number") {
        //只传一个
        this.$emit("input", "");
        this.$emit("update:uploadFileList", []);
      } else if (typeOf(this.value) == "Array") {
        //多选
        let fileIds = this.value.filter(item => {
          return item != file.fileId;
        });
        this.$emit("input", fileIds);
        this.$emit("update:uploadFileList", fileList);
      }
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/.el-avatar-uploader {
  .el-upload-list__item.is-ready {
    display: none;
  }
  .el-upload-list__item {
    transition: none !important;
    .el-icon-close-tip {
      display: none !important;
    }
  }
}
</style>
